import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import AudioPlayer from "../pages/components/audioplayer";
import "../css/index.css";
import ChristChurch from "../images/christchurch-interior.png";
import Handshake from "../images/sketches/handshake.svg";
import Ornate from "../images/sketches/ornate-breaker.svg";
import Easel from "../images/sketches/easel.svg";
import SetSquare from "../images/sketches/setsquare.svg";
import Next from "../images/logos/next-button.svg";
import Previous from "../images/logos/previous-button.svg";
import Slide from "react-reveal/Slide";
import TestAudio from "../audio/WATERFORD Stop11.mp3";

function List(props) {
  return (
    <Slide left>
      <li className="list-arrow py-4 pl-2 text-white">
        <a href={props.link}>{props.name}</a>
      </li>
    </Slide>
  );
}

export default function MostHolyTrinity() {

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(false);

  const cards = [
    {
      id: 15,
      cardName: "Christ Church Cathedral",
      text: "https://www.christchurchwaterford.com/",
      active: true,
    },
    {
      id: 16,
      cardName: "Mount Congreve House",
      text: "https://mountcongreve.com/",
      active: true,
    },
  ];

  return (
    <Layout pageTitle="Cathedral of the Most Holy Trinity">
    <div className="lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Cathedral of the Most Holy Trinity</title>
      <meta name="description" content="Cathedral of the Most Holy Trinity. A location on the digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Cathedral of the Most Holy Trinity" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/mostholytrinity" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

      <div className="w-full bg-waterford-ocean h-24"></div>
      <section className="mostholytrinity-bg w-full">
        <div className="w-9/12">
          <h1 className="font-lower p-6 text-white">
            <span className="font-heading text-xl">
              <Trans>MostHolyTrinity</Trans>
            </span>
            <br />
            <span className="font-bold text-4xl"><Trans>MostHolyTrinitySubtitle</Trans></span>
          </h1>
        </div>
        <div className="pt-64 text-center">
          <div className="text-center">
            <AudioPlayer url={TestAudio} transcript={'/transcripts/robertstranscript'}/>
          </div>
        </div>
      </section>

      <div>
        <div className="accordion pt-16 bg-waterford-ocean"></div>
      </div>

      <button className="p-6 w-full bg-waterford-ocean ring ring-waterford-cyan-30 text-waterford-orange text-left">
        <div
          className="accordion-title grid grid-cols-6 py-6"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="col-span-5 font-lower text-2xl">
            <Trans>LearnMore</Trans>
          </div>
        </div>
        {isActive && (
          <>
            <ul className="accordion-content list-outside font-heading text-xl pl-12">
              {cards.map((card) => (
                <List key={card.id} name={card.cardName} link={card.text} />
              ))}
            </ul>
          </>
        )}
      </button>

      <section className="location-hero py-16">
        <div class="tab-content" id="tabs-tabContentJustify">
          <div
            class="tab-pane fade show active"
            id="tabs-homeJustify"
            role="tabpanel"
            aria-labelledby="tabs-home-tabJustify"
          >
            <div className="w-7/12">
              <h1 className="font-lower text-white p-6">
                <br />
                <span className="font-bold text-4xl"><Trans>DidYouKnow</Trans></span>
              </h1>
            </div>
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-6 lg:mx-auto">1</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  John Roberts was a prolific builder!
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  Apart from this Cathedral, John Roberts also designed Christ
                  Church Cathedral, the new Bishop's Palace, Mount Congreve,
                  Theatre Royal and City Hall!
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img
                    className="w-full col-span-2"
                    src={SetSquare}
                    alt="Set Square"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl col-span-2 font-bold mt-2">
                  John Robert's wife was a Huguenot heiress
                </p>
                <h3 className="text-8xl lg:mx-auto">2</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white text-sm col-span-2">
                  Mary Susannah Sautelle and John Roberts eloped when he was
                  just 17! Her family were Huguenots, descendents of French
                  Protestants who moved to Waterford.
                </p>
              </div>
              <div className="py-6">
                <img className="w-full" src={Ornate} alt="Ornate" />
              </div>
            </div>

            <div className="text-waterford-cyan w-6/6 py-6">
              <div className="grid grid-cols-2">
                <div>
                  <div className=" gap-0 font-lower">
                    <h3 className="text-8xl ml-4 lg:mx-auto">3</h3>
                    <p className="text-xl ml-6 col-span-2 font-bold mt-2">
                      John and Mary had over 20 children!
                    </p>
                  </div>
                  <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                    <p className="text-white text-sm">
                      Unfortunately, not all of them survived until adulthood,
                      but two sons became famous painters in their own right,
                      Thomas Roberts and Thomas Sautelle Roberts.
                    </p>
                  </div>
                </div>

                <div className=" py-6 grid grid-cols-3">
                  <Slide top>
                    <img
                      className="w-full col-span-3 px-2"
                      src={Easel}
                      alt="Easel"
                    />
                  </Slide>
                </div>
              </div>
            </div>

            <div className="text-waterford-blue w-6/6 py-6">
              <div className="w-7/12 pt-16">
                <h1 className="font-lower text-white p-6">
                  <span className="font-heading text-xl">
                    <Trans>MostHolyTrinity</Trans>
                  </span>
                  <br />
                  <span className="font-bold text-4xl"><Trans>Timeline</Trans></span>
                </h1>
              </div>
              <div className="text-waterford-red w-6/6 py-6 px-6">
                <div className="py-6 grid grid-cols-3">
                  <img
                    className="w-full col-span-2"
                    src={ChristChurch}
                    alt="ChristChurch"
                  />
                  <div className="w-full"></div>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1712</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    John Roberts was born in Waterford. His Grandfather had
                    moved to Waterford from Wales and settled the family in the
                    city.
                  </p>
                </div>
              </div>

              <div className="bg-cyan-circle text-waterford-ocean w-6/6 py-32">
                <div className="py-6 grid grid-cols-3">
                  <img
                    className="w-full col-span-3 pb-12"
                    src={Handshake}
                    alt="Handshake"
                  />
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6">
                  <h3 className="text-6xl lg:mx-auto">1746</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading pb-12 w-7/12">
                  <p className=" text-sm">
                    John completed the New Bishop’s Palace in Waterford. Two
                    other architects had been commissioned to build the palace,
                    but both died before it was completed!
                  </p>
                  <div></div>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1788</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    He built many important buildings across Waterford, such as
                    the Assembly Rooms on the Mall, now the Theatre Royal and
                    City Hall.
                  </p>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="py-6 lg:mx-auto  gap-2">
                  <div className="px-6">
                    <h3 className="text-6xl lg:mx-auto font-lower pb-4">
                      1793
                    </h3>
                    <p className="text-white text-sm font-heading">
                      The Cathedral of the Most Holy Trinity was designed by
                      John Roberts and has the distinction of being the oldest
                      Post-Reformation cathedral of the Roman Catholic Church in
                      Ireland.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <Link to="/blackfriars">
              <img className="w-2/6 mx-auto" src={Previous} alt="Previous" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>PreviousStop</Trans>
            </p>
          </div>
          <div>
            <Link to="/clocktower">
              <img className="w-2/6 mx-auto" src={Next} alt="Next" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>NextStop</Trans>
            </p>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;